import React, { useState } from 'react';
import { Utils } from '../index';
import { useNavigate } from "react-router";

const OnboardSteps = ({handleSetOnboard}) => {
    const navigate = useNavigate()
    let userData = JSON.parse(localStorage.getItem('userData'));
    const [appName,setAppName] = useState("")



    function handleSaveApp() {
        console.log(appName);
        let data = {
            onboard:true,
            application_name:appName,
            userId:userData.id
        }
        fetch(Utils.baseurl + 'update/application', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then(async (result) => {
              console.log("signin result ", result);
              if (result.status) {
                handleSetOnboard()
                navigate(`/push-notification`);
              } else {
                alert(result.message)
              }
            })
            .catch((error) => console.error(error));
    }


    return (
        <div className="steps_page">
            <div className="steps_outer">
                <div className="steps_inner">
                    <div className="steps_heading">
                        <h1 color="black">🎉 Welcome to NotifySphere!</h1>
                    </div>
                    <div className="steps_form_outer">
                        <div className="steps_left">
                            <div className="inner_heading">
                                <h2 color="black">Now add your first app</h2>
                            </div>
                            <div className="steps_sub_heading">
                               Need Help?
                            </div>
                            <div className="steps_form">
                                {/* <div className="name_field">
                                    <div className="field_name">
                                        <label htmlFor="first-name">First Name</label>
                                        <div className="input_field">
                                            <input id="first_name" value="" />
                                        </div>
                                        <div aria-describedby="first-name" className="validation_error">
                                            <span>
                                                <img src={error_icon} alt="" />
                                                Please enter a response</span>
                                        </div>
                                    </div>
                                    <div className="field_name">
                                        <label htmlFor="last-name">Last Name</label>
                                        <div className="input_field">
                                            <input id="last_name" value="" />
                                        </div>
                                         <div aria-describedby="last-name" className="validation_error">
                                            <span>
                                                <img src={error_icon} alt="" />
                                                Please enter a response</span>
                                        </div> 
                                    </div>
                                </div>
                                <div className="category_field">
                                    <label htmlFor="company-role">Which category best describes your role?</label>
                                    <div className="select_field">
                                        <select className="" name="">
                                            <option value="">Select...</option>
                                            <option value="">C-Suite</option>
                                            <option value="">Developer</option>
                                            <option value="">Product</option>
                                        </select>
                                    </div>
                                    <div aria-describedby="last-name" className="validation_error">
                                        <span>
                                            <img src={error_icon} alt="" />
                                            Please enter a response</span>
                                    </div>
                                </div>
                                <div className="select_apply_section">
                                    <div className="select_section_label">
                                        <label htmlFor="">What do you primarily want to use OneSignal htmlFor? (Select all that apply)</label>
                                    </div>
                                    <div className="select_boxes">
                                        <div className="select_box">
                                            <input id="" type="checkbox" />
                                            <label htmlFor="Drive visits to website/app (e.g. onboarding, updates and reminders)_0">
                                                <span>Drive visits to website/app (e.g. onboarding, updates and reminders)</span>
                                            </label>
                                        </div>
                                        <div className="select_box">
                                            <input id="" type="checkbox" />
                                            <label htmlFor="Drive visits to website/app (e.g. onboarding, updates and reminders)_0">
                                                <span>Drive visits to website/app (e.g. onboarding, updates and reminders)</span>
                                            </label>
                                        </div>
                                        <div className="select_box">
                                            <input id="" type="checkbox" />
                                            <label htmlFor="Drive visits to website/app (e.g. onboarding, updates and reminders)_0">
                                                <span>Drive visits to website/app (e.g. onboarding, updates and reminders)</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="select_apply_section">
                                    <div className="select_section_label">
                                        <label htmlFor="">What do you primarily want to use OneSignal htmlFor? (Select all that apply)</label>
                                    </div>
                                    <div className="select_boxes">
                                        <div className="select_box">
                                            <input id="" type="checkbox" />
                                            <label htmlFor="Drive visits to website/app (e.g. onboarding, updates and reminders)_0">
                                                <span>Drive visits to website/app (e.g. onboarding, updates and reminders)</span>
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="phone_section">
                                    <label >Name of your app</label>
                                    <div className="phone_input">
                                        {/* <input id="phone-number" maxlength="30" value="" /> */}
                                        <input
                                        type="text"
                                        className="phone_input"
                                        placeholder="App Name"
                                        value={appName}
                                        onChange={(e) => setAppName(e.target.value)}
                                    />
                                    </div>
                                </div>
                            </div>
                            <button className="form_btn" onClick={handleSaveApp} disabled={!appName.length?true:false} style={{backgroundColor:!appName.length?"#6f709f": "rgb(67, 70, 206)",border: !appName.length?"#6f709f":"1px solid rgb(58, 61, 179)"}}>Continue</button>
                        </div>
                        {/* <div className="steps_right">
                            <div className="step_right_heading">0/3 Steps Completed</div>
                            <div className="step_progress">
                                <div className="progress_bar">
                                </div>
                            </div>
                            <div className="step_listing">
                                <div className="step_list">
                                    <div className="step_count">1</div>
                                    <div className="step_seperator">
                                    </div>
                                    <label >About You</label>
                                </div>
                                <div className="step_list">
                                    <div className="step_count">2</div>
                                    <div className="step_seperator">
                                    </div>
                                    <label >About You</label>
                                </div>
                                <div className="step_list">
                                    <div className="step_count">3</div>
                                    <div className="step_seperator">
                                    </div>
                                    <label >About You</label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default OnboardSteps;
