import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dashboardIcon from '../assets/icons/dashboard.png';
import feedbackIcon from '../assets/icons/feedback.png';
import leaveIcon from '../assets/icons/leave.png';
import attendanceIcon from '../assets/icons/attendance.png';
import timesheetIcon from '../assets/icons/timesheet.png';
import ReimbursementsIcon from '../assets/icons/Reimbursements.png';
import ReportIcon from '../assets/icons/report.png';
import ExpenseIcon from '../assets/icons/expense.webp';
import bellIcon from '../assets/icons/bellIcon.png'
import IncomeIcon from '../assets/icons/Income.png';
import CategoryIcon from '../assets/icons/category.png';
import SettingIcon from '../assets/icons/setting.png';
import Audience from '../assets/icons/Audience.png';
import Logo from '../assets/logonotify.png'
import SmallLogo from '../assets/smallLogo.png'
import { Utils } from '../index';

import Dropdown from 'react-bootstrap/Dropdown';
import { FaCheck } from "react-icons/fa6";

const Sidebar = ({ menuItems }) => {
  const location = useLocation();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [activeApp,setActiveApp] = useState("medcare")
  const [application,setApplication] = useState([])
  const iconObj = {
    dashboard: dashboardIcon,
    feedbacks: feedbackIcon,
    leaves: leaveIcon,
    attendance: attendanceIcon,
    "daily-timesheet": timesheetIcon,
    subscriptions: Audience,
    reimbursements: ReimbursementsIcon,
    reports: ReportIcon,
    "my-expenses": ExpenseIcon,
    income: IncomeIcon,
    categories: CategoryIcon,
    reciept:CategoryIcon,
    settings: SettingIcon,
    "push-notification":bellIcon,
    platforms:CategoryIcon
  };

  const activeIndex = menuItems.findIndex(item => `/${item.route}` === location.pathname);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 768);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    let userData = JSON.parse(localStorage.getItem('userData'))
    console.log(userData);
    
// for fetching application     
let data = {
userId:userData.id
}

fetch(Utils.baseurl + 'getall/application', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
})
  .then((response) => response.json())
  .then(async (result) => {
    
    if (result.status) {
      setApplication(result.data)
      localStorage.setItem('application',JSON.stringify(result.data))
      let activeApplication =  result.data.find(item => item.is_active === 1);
      setActiveApp(activeApplication.application_name)

    }
    
  })
  .catch((error) => console.error(error));
  }, []);

  console.log(application);
  

  const getLinkStyle = (index) => ({
    borderRadius: 6,
    backgroundColor: index === activeIndex ? "#f1f1f1" : 'white',
    color: index === activeIndex || hoveredIndex === index ? "#0b0f12" : '#646464',
    textDecoration: 'none',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: isLargeScreen ? 'flex-start' : 'center', // Center icons on smaller screens
    padding: isLargeScreen ? '10px 15px' : '10px 0', // Adjust padding for small screens
    fontSize: isLargeScreen ? '16px' : '0px', // Hide text on smaller screens
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'background-color 0.3s, color 0.3s',
  });

  return (
    <div
      className="d-flex flex-column vh-100  border-end sidebar"
      style={{
        minWidth: isLargeScreen ? '250px' : '80px', // Narrow width for small screens
        maxWidth: isLargeScreen ? '14.5%' : '80px', // Narrow max width for small screens
        flexShrink: 0,
        transition: 'min-width 0.3s, max-width 0.3s',
      }}
    >
      {isLargeScreen && (
        <div className="mb-3">
          <img src={Logo} height="40px"width="200px"/>
          {/* <h4 style={{ fontSize: isLargeScreen ? '20px' : '16px' }}>My Options</h4> Title visible only on large screens */}
        </div>
      )}
     {!isLargeScreen && (
        <div className="mb-2">
          <img className="me-2" src={SmallLogo} height="28px"/>
          {/* <h4 style={{ fontSize: isLargeScreen ? '20px' : '16px' }}>My Options</h4> Title visible only on large screens */}
        </div>
      )}
      <div style={{marginLeft:"7%"}}>
      <Dropdown>
      <Dropdown.Toggle style={{backgroundColor:"white",color:"black",border:"1px solid  black",borderRadius:"5px"}} id="dropdown-basic">
      {activeApp} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {application.map((item,index)=>(
          !index?<Dropdown.Item key={index} href="#/action-1" ><FaCheck />&nbsp;{item.application_name}</Dropdown.Item>: <Dropdown.Item key={index} href="#/action-1" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.application_name}2</Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item href="#/action-2" style={{color:"blue"}} >+ New App</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      </div>
      <ul style={{marginTop:"4%"}} className="nav flex-column">

        {menuItems.map((item, index) => (
          <li key={index} className="nav-item mb-2">
            <Link
              to={`/${item.route}`}
              className="nav-link"
              style={getLinkStyle(index)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img 
                src={iconObj[item.route]} 
                height={isLargeScreen ? '32px' : '28px'} 
                alt={item.name} 
                className="me-2" 
                style={{ marginRight: isLargeScreen ? '10px' : '0px' }} // Adjust margin for small screens
              />
              {isLargeScreen && <span>{item.name}</span>} {/* Text visible only on large screens */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
