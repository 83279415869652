import React from 'react';

const Subscriptions = () => {
   
    return (
        <div className="container my-4">
           Subscriptions
        </div>
    );
};

export default Subscriptions;
