import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router";
import { Utils } from '../index';

const Platforms = (handleSetOnboard) => {
  const [fileName, setFileName] = useState(null);
  const [activeApp, setActiveApp] = useState({});
  const [jsonContent, setJsonContent] = useState(null);
  const navigate = useNavigate();


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      setFileName(file.name);
      const reader = new FileReader();

      // Read the file content
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);  // Parse JSON content
          setJsonContent(json);
          console.log('JSON Content:', json); // You can process the JSON as needed
        } catch (err) {
          alert('Invalid JSON file.');
        }
      };

      reader.readAsText(file); // Read file as text
    } else {
      alert('Please upload a valid JSON file.');
    }
  };

  useEffect(() => {
    setActiveApp(JSON.parse(localStorage.getItem('application')).find(item => item.is_active === 1))
  }, [localStorage.getItem('application')]);
    console.log(activeApp);

  const handleSave = () => {
    if (jsonContent) {
      let data = {
        id:activeApp.id,
        application_json: JSON.stringify(jsonContent),
        json_name: fileName
      }
      fetch(Utils.baseurl + 'update/application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (result) => {
          console.log("signin result ", result);
          if (result.status) {
            handleSetOnboard()
            navigate(`/push-notification`);
          } else {
            alert(result.message)
          }
        })
        .catch((error) => console.error(error));

      // Here you can handle the JSON data, like sending it to a server or using it in the app.
      console.log('Saving JSON Data:', jsonContent);
      alert('JSON file processed successfully.');
      navigate("/push-notification")
    } else {
      alert('No JSON data to save.');
    }
  };

  return (
    <div style={styles.container}>
      <h2>Google Android (FCM) Configuration</h2>
      <p>Now, let's configure your app. Upload your JSON configuration file below.</p>

      <div style={styles.fileInputWrapper}>
        <input
          type="file"
          id="file-upload"
          onChange={handleFileUpload}
          accept="application/json"
          style={styles.fileInput}
        />
        <label htmlFor="file-upload" style={styles.label}>
          {fileName ? fileName : 'Choose File'}
        </label>
      </div>

      <button style={styles.button} onClick={handleSave}>Save & Continue</button>
      {/* <button style={styles.goBackButton}>Go Back</button> */}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  fileInputWrapper: {
    margin: '20px 0',
    position: 'relative',
  },
  fileInput: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  },
  label: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#3b82f6',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  goBackButton: {
    padding: '10px 20px',
    backgroundColor: '#e0e0e0',
    color: '#333',
    border: 'none',
    borderRadius: '4px',
    marginLeft: '10px',
    cursor: 'pointer',
  },
};

export default Platforms;
