import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { getAuthToken } from "./components/Auth";
import Dashboard from './components/Dashboard';
import PushNotification from './components/PushNotification';
import Settings from './components/Settings';
import { AuthProvider } from './components/AuthContext';
import NewSignUp from './components/NewSignUp';
import NewSignIn from './components/NewSignIn';
import Plan from './components/Plan';
import Platforms from './components/Platforms';
import OnboardSteps from './components/OnboardSteps';
import Subscriptions from './components/Subscriptions';

const App = () => {
  const [token, setToken] = useState(null);
  const [onboard, setOnboard] = useState(false);




  // Function to handle setting the token
  function handleSaveToken(token, userData) {
    console.log(userData);
    setToken(token);
    localStorage.setItem('token', token);  // Save token in localStorage (for persistence after page reload)

    localStorage.setItem('userData', userData);
  }


  const handleSetOnboard = () => {
    setOnboard(true);
    localStorage.setItem('onboard', true);  // Save onboard status in localStorage
  };

  // Function to handle removing the token (on sign out)
  const handleRemoveToken = () => {
    setToken(null);
    setOnboard(false);
    localStorage.removeItem('application');
    localStorage.removeItem('token');  // Remove token from localStorage
    localStorage.removeItem('userData');
    localStorage.removeItem('onboard');
  };

  // Load token and onboarding status from localStorage (for session persistence)
  useEffect(() => {
    const savedToken = getAuthToken();
    const savedOnboard = localStorage.getItem('onboard') === 'true';
    if (savedToken) {
      setToken(savedToken);
    }
    if (savedOnboard) {
      setOnboard(true);
    }
  }, []);


  // Menu items and corresponding components
  const menuItems = [
    { name: 'Dashboard', route: 'dashboard', component: Dashboard },
    { name: 'Push Notification', route: 'push-notification', component: PushNotification },
    { name: 'Platforms', route: 'platforms', component: Platforms },
    { name: 'Subscriptions', route: 'subscriptions', component: Subscriptions },
    { name: 'Settings', route: 'settings', component: Settings },
  ];

  console.log(token);
  console.log(onboard);
  
  


  return (
    <Router>
      <AuthProvider>
        <div >
          {token && onboard ? (
            // If authenticated, show Sidebar, Header, and protected routes
            <>
              <Sidebar menuItems={menuItems} />
              <div className="flex-grow-1" style={{ marginLeft: "14%", backgroundColor: "#f3f3f3" }}>
                <Header handleRemoveToken={handleRemoveToken} />
                <div style={{ margin: "1.8%" }}>
                  <Routes>
                    {menuItems.map((item) => (
                      <Route key={item.route} path={`/${item.route}`} element={<item.component />} />
                    ))}
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                  </Routes>
                </div>
              </div>
            </>
          ) : token && !onboard ? (<Routes>
              <Route path="/" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard}/>} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/steps" element={<OnboardSteps handleSetOnboard={handleSetOnboard} />} />
            <Route path="/signIn" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
            <Route path="/signup" element={<NewSignUp handleSaveToken={handleSaveToken} />} />
            <Route path="*" element={<Navigate to="/plan" />} />
          </Routes>) : (
            // If not authenticated, show SignIn or SignUp
            <Routes>
              <Route path="/" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
              <Route path="/signIn" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
              <Route path="/signup" element={<NewSignUp handleSaveToken={handleSaveToken} />} />
              
              {/* Redirect all other routes to SignIn if unauthenticated */}
              <Route path="*" element={<Navigate to="/signIn" />} />
            </Routes>
          )}
        </div>
      </AuthProvider>
    </Router>
  );
};


// <Routes>
// { token && !isPlan ? <Route path="/plan" element={<Plan handleSetOnboard={handleSetOnboard} />} />:""}
// </Routes>
export default App;
