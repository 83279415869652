
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Utils } from '../index';
import { useAuth } from './AuthContext';
import { useNavigate } from "react-router";
import './signup.css'
import eyeOpenImg from "../assets/icons/eye.png"
import eyeCloseImg from "../assets/icons/invisible.png"
import logo from "../assets/logonotify.png"


function NewSignIn({ handleSaveToken ,handleSetOnboard }) {
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailStyle, setEmailStyle] = useState({});
  const [passwordStyle, setPasswordStyle] = useState({});
  const [formError,setFormError] = useState("")
  const {  isAuthenticated } = useAuth(); // Get isAuthenticated from useAuth
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // When isAuthenticated is true, navigate to push-notification
    if (isAuthenticated) {
      navigate(`/push-notification`);
    }
  }, [isAuthenticated, navigate]); // Depend on isAuthenticated

  function handleSignIn(e) {
    console.log(emailStyle,passwordStyle);
    
    e.preventDefault(); 
    let valid = true;
    setEmailError('');
    setPasswordError('');
    setEmailStyle({})
    setPasswordStyle({})

    // Basic email and password validation
    if (!email) {
      setEmailError('Email is required');
      setEmailStyle({margin:"0"})
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address');
      setEmailStyle({margin:"0"})
      valid = false;
    }

    if (!password) {
      setPasswordError('Password is required');
      setPasswordStyle({margin:"0"})
      valid = false;
    } else if (password.length < 6) {
      setPasswordError('Password should be at least 6 characters');
      setPasswordStyle({margin:"0"})
      valid = false;
    }

    if (!valid) {
      return; // If validation fails, stop here
    }

    let data = {
      email: email,
      password: password,
    };

    fetch(Utils.baseurl + 'signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (result) => {
        console.log("signin result ", result);
        if (result.status) {
          handleSaveToken(result.accessToken,JSON.stringify(result.data))
          handleSetOnboard()
          navigate(`/push-notification`);
        } else {
          // Handle server-side validation errors (optional)
          // if (result.errors) {
          //   if (result.errors.email) setEmailError(result.errors.email);
          //   if (result.errors.password) setPasswordError(result.errors.password);
          // }
          setFormError(result.message)
        }
      })
      .catch((error) => console.error(error));
  }

  const handleCheckboxChange = (e) => {
    console.log("cechk  << ",e.target.checked);
    
    setIsChecked(e.target.checked);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    setFormError("");
    setEmailStyle({})
    setEmailError("")
  }, [email]);


  useEffect(() => {
    setFormError("");
    setPasswordStyle({})
    setPasswordError("")
  }, [password]);

  return (
    <div className="signup_page">
    <div className="page_logo">
    <a href="#" style={{textAlign: "center"}}>
				<span>
					<img style={{width:"40%"}} src={logo} alt=""/>
				</span>
			</a>
    </div>
    <div className="form_outer">
        <div className="text-center">
            <div className="form_heading">
                <h1 color="black">Get Started Today</h1>
                <p style={{color:formError?"red":"black"}}>{formError?formError:"Quick setup, no credit card required."}</p>
            </div>
        </div>


        <div className="login_form">
            <form onSubmit={handleSignIn}>
                <div className="input_boxes">
                    <div className="email_box">
                        <label htmlFor="field_email"> Email Address</label>
                        <div className="email_input">
                            <input id="field_email"   onChange={(e) => setEmail(e.target.value)} spellCheck="false"/>
                        </div>
                        {emailError && <span style={{color:"#ff0000d1"}} >{emailError}</span>}
                    </div>
                    <div className="password_box">
                        <label htmlFor="field_password">Password</label>
                        <div className="email_input password_input">
                            <input id="field_password" type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)}/>
                            <img
                            src={showPassword ? eyeCloseImg : eyeOpenImg}
                            alt="Toggle Password"
                            onClick={toggleShowPassword}
                            />
                        </div>
                            {passwordError && <span style={{color:"#ff0000d1"}} >{passwordError}</span>}
                        {/* <span color="red">8 character minimum</span> */}
                    </div>
                </div>
                <div className="recaptcha">
                    <input id="recaptcha" type="checkbox" className="checkbox"  onChange={handleCheckboxChange} />
                    <div className="recaptcha_lable">
                        <label htmlFor="recaptcha" className="sc-dwLEzm jwPkpm">
                            <span>
                                <span color="dark">I agree to notifySphere's <a href="#" target="_blank"
                                        rel="noopener noreferrer" tabIndex="-1">
                                        <span>Terms</span>
                                    </a> and <a href="#" target="_blank" rel="noopener noreferrer" tabIndex="-1">
                                        <span>Privacy Policy</span>
                                    </a>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="signup_button"  >
                    <button disabled={!isChecked} style={{backgroundColor:isChecked?"rgb(67, 70, 206)":"#7374bf",border:isChecked?"rgb(67, 70, 206)":"#7374bf"}} type='submit'>
                        <span>Sign in</span>
                    </button>
                </div>
            </form>
            <div className="already_acc">
                <span color="dark">Not have an account?<Link to="/signUp">Signup</Link>
                </span>
            </div>
        </div>
    </div>
</div>
  );
}

export default NewSignIn;
