import React from 'react';
import "./plan.css"
import { useNavigate } from 'react-router-dom';
import { Utils } from '../index';
const Plan = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    console.log("userData ", userData);
const navigate = useNavigate()

    let plan = [{0:"free"},{ 1:"Growth"}, {2:"Professional"}, {3:"Enterprise"}]

    function handleSavePlan(type) {
        console.log(type);
        let data = {
            plan_type:type,
            userId:userData.id
        }

        fetch(Utils.baseurl + 'save/plan-type', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then(async (result) => {
              console.log("signin result ", result);
              if (result.status) {
                navigate(`/steps`);
              } else {
                alert(result.message)
              }
            })
            .catch((error) => console.error(error));

        
    }


    return (
        <div className="plan_page">
            <h1 style={{ color: "inherit" }}>Choose Your Plan</h1>
            <a href="#" target="_blank" rel="noreferrer">
                <span>View full pricing + feature comparison</span>
            </a>
            <div className="plan_boxes">
                {plan.map((item,index) => (
                    <div className="plan_box" id={item[index]}>
                        <div className="plan_inner">
                            <div className="plan_content">
                                <h2 style={{ color: "black" }}>{item[index]}</h2>
                                <p style={{ color: "dark" }}>Powerful essentials for <br />small businesses</p>
                                <div className="plan_price"> <h3 style={{ color: "black" }}>$0 / Month</h3>
                                </div>
                                <p style={{ color: "dark" }} className="plan_desc">
                                    <span style={{ color: "dark" }}>Free access to all channels<br />Message limits may apply</span>
                                </p>
                                <button className="plan_button" onClick={()=>handleSavePlan(index+1)}>
                                    Get Started Now
                                </button>
                            </div>
                            <div className="descriptions">
                                <p style={{ color: "dark" }}>Free access to:</p>
                                <ul>
                                    <li>
                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                            <g fill="currentColor">
                                                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                                </path>
                                            </g>
                                        </svg>
                                        <span style={{ color: "dark" }}>10,000/mth Free Email Sends</span>
                                    </li>
                                    <li>
                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                            <g fill="currentColor">
                                                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                                </path>
                                            </g>
                                        </svg>
                                        <span style={{ color: "dark" }}>Unlimited Mobile Push Sends</span>
                                    </li>
                                    <li>
                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                            <g fill="currentColor">
                                                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                                </path>
                                            </g>
                                        </svg>
                                        <span style={{ color: "dark" }}>Journey Workflows</span>
                                    </li>
                                    <li>
                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                            <g fill="currentColor">
                                                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                                </path>
                                            </g>
                                        </svg>
                                        <span style={{ color: "dark" }}>GDPR Compliant</span>
                                    </li>
                                    <li>
                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                            <g fill="currentColor">
                                                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                                </path>
                                            </g>
                                        </svg>
                                        <span style={{ color: "dark" }}>A/B Testing</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}

                {/* <div className="plan_box" id="Growth">
                    <div className="plan_inner">
                        <div className="plan_content">
                            <h2 style={{ color: "black" }}>Growth</h2>
                            <p style={{ color: "dark" }}>Powerful essentials for <br />small businesses</p>
                            <div className="plan_price"> <h3 style={{ color: "black" }}>$0 / Month</h3>
                            </div>
                            <p style={{ color: "dark" }} className="plan_desc">
                                <span style={{ color: "dark" }}>Free access to all channels<br />Message limits may apply</span>
                            </p>
                            <button className="plan_button" href="#">
                                Get Started Now
                            </button>
                        </div>
                        <div className="descriptions">
                            <p style={{ color: "dark" }}>Free access to:</p>
                            <ul>
                                <li>
                                    <svg viewBox="0 0 16 16" fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>10,000/mth Free Email Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Unlimited Mobile Push Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Journey Workflows</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>GDPR Compliant</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>A/B Testing</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="plan_box" id="Professional">
                    <div className="plan_inner">
                        <div className="plan_content">
                            <h2 style={{ color: "black" }}>Professional</h2>
                            <p style={{ color: "dark" }}>Powerful essentials for <br />small businesses</p>
                            <div className="plan_price"> <h3 style={{ color: "black" }}>$0 / Month</h3>
                            </div>
                            <p style={{ color: "dark" }} className="plan_desc">
                                <span style={{ color: "dark" }}>Free access to all channels<br />Message limits may apply</span>
                            </p>
                            <button className="plan_button" href="#">
                                Get Started Now
                            </button>
                        </div>
                        <div className="descriptions">
                            <p style={{ color: "dark" }}>Free access to:</p>
                            <ul>
                                <li>
                                    <svg viewBox="0 0 16 16" fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>10,000/mth Free Email Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Unlimited Mobile Push Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Journey Workflows</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>GDPR Compliant</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>A/B Testing</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="plan_box" id="Enterprise">
                    <div className="plan_inner">
                        <div className="plan_content">
                            <h2 style={{ color: "black" }}>Enterprise</h2>
                            <p style={{ color: "dark" }}>Powerful essentials for <br />small businesses</p>
                            <div className="plan_price"> <h3 style={{ color: "black" }}>$0 / Month</h3>
                            </div>
                            <p style={{ color: "dark" }} className="plan_desc">
                                <span style={{ color: "dark" }}>Free access to all channels<br />Message limits may apply</span>
                            </p>
                            <button className="plan_button" href="#">
                                Get Started Now
                            </button>
                        </div>
                        <div className="descriptions">
                            <p style={{ color: "dark" }}>Free access to:</p>
                            <ul>
                                <li>
                                    <svg viewBox="0 0 16 16" fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>10,000/mth Free Email Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Unlimited Mobile Push Sends</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>Journey Workflows</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>GDPR Compliant</span>
                                </li>
                                <li>
                                    <svg viewBox="0 0 16 16"  fill="currentColor">
                                        <g fill="currentColor">
                                            <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm4.618 5.467l-4.563 6.192a.674.674 0 01-.954.126L3.843 9.179a.667.667 0 11.833-1.041l2.717 2.174 4.152-5.634a.665.665 0 011.129.031.669.669 0 01-.056.758z">
                                            </path>
                                        </g>
                                    </svg>
                                    <span style={{ color: "dark" }}>A/B Testing</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Plan;
